function initializeParallax () {
    let parallaxContainer = document.querySelector('.parallax-container'),
        viewportHeight,
        containerHeight,
        contentHeight,
        scrollableParallaxHeight,
        scrollableContentHeight;

    function recalculateValues () {
        viewportHeight = window.innerHeight;
        containerHeight = parallaxContainer.offsetHeight;
        contentHeight = document.body.clientHeight;
        scrollableParallaxHeight = containerHeight - viewportHeight;
        scrollableContentHeight = contentHeight - viewportHeight;
    }

    function moveBackground() {
        recalculateValues();
        let scrollProgress = 1 - (scrollableContentHeight - window.pageYOffset) / scrollableContentHeight;
        parallaxContainer.style.marginTop = -1 * scrollableParallaxHeight * scrollProgress + 'px';
    }

    moveBackground();

    document.addEventListener('scroll', moveBackground);
    window.addEventListener('resize', function () {
        moveBackground();
    });
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', function () {
        initializeParallax();
    });
} else {
    initializeParallax();
}