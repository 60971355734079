const ckeditorSelector = '.textarea--ckeditor';
const triggerEventName = 'content.inserted';

function initCkeditors() {
    const init = () => {
        let ckeditors = document.querySelectorAll(ckeditorSelector);
        if(ckeditors.length > 0) {
            import(/* webpackChunkName: "ckeditor-init-lazy" */ './../ckeditor-init').then(module => {
                const initCkeditorss = module.default;
                initCkeditorss(ckeditorSelector);
            })
        }
    }

    init();
    document.addEventListener(triggerEventName, () => init());
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initCkeditors);
} else {
    initCkeditors();
}